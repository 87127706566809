@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --size: 20px;
}

html,
body,
#root,
main {
  height: 100%;
}

body {
  font-family: "Poppins", sans-serif;
  background: #282a36;
}

main {
  display: flex;
  flex-direction: column;
}

.header {
  font-size: 2rem;
  font-weight: bold;
  color: #f8f8f2;
  text-align: center;
}

.header h1 {
  margin-bottom: 1rem;
}

button {
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  letter-spacing: 0.5ch;
  font-size: 1.5rem;
  border: none;
  transition: all 200ms;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  min-width: 200px;
}

header button + button {
  margin-left: 1rem;
}

button:hover,
button:focus {
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
  transform: scale(1.1);
  box-shadow: 0 0 10px #0005;
}

button.reset {
  background: #ff5555;
}

button.start {
  background: #50fa7b;
}

button.random {
  background: #ff79c6;
}

.cell {
  --aliveCol: #8be9fd;
  --deadCol: #6272a4;
  width: 20px;
  width: var(--size);
  height: 20px;
  height: var(--size);
  transition: all 200ms, background 100ms;
  outline: 1px solid #44475a;
}

.cell:hover {
  transform: scale(1.3);
  box-shadow: 0 0 10px #0005;
  z-index: 100;
}

.row {
  display: flex;
  justify-content: center;
}

.grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

